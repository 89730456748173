// 登录接口
import {
	axios
} from '../utils/http.js'

//获取二维码
export function wxQr(data) {
	return axios({
		url: 'v3/WxPay/Get_QRCode',
		method: 'post',
		data:data
	})
}
//获取二维码
export function wxState(data) {
	return axios({
		url: 'v3/WxPay/GetOrderState',
		method: 'post',
		data:data
	})
}